@import '../../../variables'

.StepsMap
  // height: calc( 100vh - 141px)
  overflow-y: hidden
  .listen-instructions
    margin-bottom: 17px
    button
      display: flex
      column-gap: 16px
      color: $parso-blue
    p
      font-size: 18px
      font-style: normal
      font-weight: 400
      line-height: normal
  .step
    cursor: pointer
    background: #FFF
    border: solid 1px #D1DEEF
    width: 249px
    min-height: 70px
    font-weight: 500
    padding: 10px
    margin-bottom: 17px
    display: flex
    align-items: center
    border-radius: 3px
    &:hover
      background: #f1f4f7
    &.selected
      background: #D1DEEF
    svg
      width: 26px
      margin-right: 20px
    p
      width: 157px
      color: $parso-high-gray
      font-size: 20px
      word-break: break-word
    &.active
      background: #D1DEEF
