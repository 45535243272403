
@import '../../variables'

.Explore
  .explore-floor-map
    background: white
    margin-left: -36px
    width: calc(100vw)
    height: calc($vh-100 - 52px)

    &.fullScreen
      margin-left: 0
      position: fixed
      left: 0
      top: 0
      width: 100vw
      height: 100vh
      z-index: 1
  .detail-map
    padding: 34px 49px
    .explore-floor-map
      width: calc(100vw - 682px)
      height: calc($vh-100 - 200px)

.how-to-get
  h1,h3
    text-align: center
  h1
    padding: 42px 45px 10px
  h3
    padding: 28px 0
    margin: 0 !important
    font-size: 20px
    color: #8C8CA1
  .how-to-get-buttons
    width: 248px
    margin: 70px auto

  .MuiOutlinedInput-root
    background-color: $parso-light-blue-light-gray

  .MuiOutlinedInput-notchedOutline
    border: none

.detail-room
  *
    font-family: 'Avenir'
  .MuiDialogContent-root
    padding: 27px 24px 34px 36px

  .title
    font-size: 22px
    font-weight: 900
    color: $parso-super-strong-gray
    text-transform: capitalize
    margin-top: 7px
  hr
    transform: scaleX(1.057)
  
  .left-container
    width: 40%
    
    p, strong
      font-size: 14px
      display: flex
      gap: 12px
      align-items: baseline


  .amenities
    color: $parso-super-strong-gray
    margin-top: 8px

  .room-image
    width: 110%
    height: 100%
    display: flex
    justify-content: center

  img
    border-radius: 10px
  
  .close-button
    path
      fill: $parso-blue
  
  .divider
    border-color: #D8DFF5
    margin-top: 26px

.MuiPaper-root
  .MuiDialogContent-root.dialog-info-content
    padding: 18px 24px
    max-width: calc(100vw - 100px)
    .detail-header
      color: $parso-blue
      text-align: center
      margin-bottom: 19px
      svg
        display: block
        margin: auto
        margin-bottom: 6px
    .detail-list
      margin-bottom: 20px
      li
        margin: 0
        line-height: 20px
    .detail-hr, p
      display: none
    p.enabled
      font-weight: 400
      font-size: 12px
      line-height: 16px
      text-align: center
      display: block
      margin: 19px 0
    .detail-footer
      justify-content: center
      min-width: 200px
      button
        width: 100%

@media screen and (min-width: $md)
  .MuiPaper-root
    .MuiDialogContent-root.dialog-info-content
      padding: 32px 75px 30px
      width: 600px
      box-sizing: border-box

      .detail-header
        margin-bottom: 0

      .detail-hr.detail-hr
        margin: 10px 0 12px
        border-color: #B2BEE5
        display: block
        position: relative
        transform: scaleX(1.33)

      .detail-remove-info
        background: #F1F5FA
        padding: 10px 13px
        border-radius: 4px
        margin: 12px 0 32px

      .detail-list
        margin-bottom: 0
        li
          margin-bottom: 8px
          line-height: 22px

      p
        color: $parso-high-gray
        display: block
        font-size: 12px

      .detail-footer
        max-width: 316px
        left: 0
        right: 0
        margin: auto
        button
          padding-top: 0
          padding-bottom: 0
