@import 'fonts/fonts'
@import 'variables'

html
  overflow: hidden

body
  width: 100%
  height: 100%
  position: fixed
  overflow-y: auto
  -webkit-overflow-scrolling: touch

*
  font-family: 'Avenir', 'Nunito Sans', Arial, Helvetica, sans-serif

  &::-webkit-scrollbar
    background-color: transparent
    cursor: pointer
    width: 7px
    height: 7px

  &::-webkit-scrollbar-button
    display: none

  &::-webkit-scrollbar-thumb
    background-color: #C8C5C54D
    border-radius: 4px

input::-webkit-contacts-auto-fill-button
  visibility: hidden
  display: none !important
  pointer-events: none
  position: absolute
  right: 0

h1
  font-size: 18px
  vertical-align: text-bottom
  font-weight: 800
  color: $parso-blue-strong
  margin: 0

h2
  font-size: 18px
  font-weight: 500
  color: $parso-blue-strong
  margin: 0

h3
  font-size: 14px
  font-weight: 500
  color: $parso-blue-strong
  margin: 0

h4
  font-size: 12px
  font-weight: 500
  color: $parso-blue-strong
  margin: 0

p
  margin: 0

.PrivatePickersToolbar-dateTitleContainer .MuiButtonBase-root.MuiIconButton-root,
.PrivateDateRangePickerToolbar-penIcon.PrivateDateRangePickerToolbar-penIcon
  display: none

.MuiFilledInput-root.MuiFilledInput-root
  background: #F1F5FA !important
  border-radius: 5px

.MuiFilledInput-root.MuiFilledInput-underline::before
  border-bottom: 0px !important

.MuiPopover-paper.MuiPopover-paper
  box-shadow: 0px 0px 6px rgb(146 146 146 / 50%)
  border-radius: 6px

.hide
  display: none !important

.no-select
  -webkit-touch-callout: none
  -webkit-user-select: none
  -khtml-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

.cp-material-bar::-webkit-scrollbar
  background-color: transparent
  cursor: pointer
  width: 7px

.cp-material-bar::-webkit-scrollbar-button
  display: none

.cp-material-bar::-webkit-scrollbar-thumb
  background-color: #C8C5C54D
  border-radius: 4px

.cp
  cursor: pointer

.m0
  margin: 0

.mr-6
  margin-right: 6px

.mb-6
  margin-bottom: 6px

.mb-15
  margin-bottom: 15px

.ml-15
  margin-left: 15px

.mt-10
  margin-top: 10px

.mb-10
  margin-bottom: 10px

.ml-auto
  margin-left: auto !important

.ml-10
  margin-left: 10px

.mr-10
  margin-right: 10px

.mt-30
  margin-top: 30px

.mb-30
  margin-bottom: 30px

.mt-60
  margin-top: 60px

.mr-15
  margin-right: 15px

.p0
  padding: 0

.pl-15
  padding-left: 15px

.pr-15
  padding-right: 15px

.pa
  position: absolute

.pr
  position: relative

.pb-0
  padding-bottom: 0

.text-right
  text-align: right

.text-left
  text-align: left

.text-end
  text-align: end

.vl-base td
  vertical-align: baseline

.w100
  width: 100%

.d-bl
  display: block

.d-ib
  display: inline-block

.d-i
  display: inline

.fr
  float: right

.fl
  float: left

.cp-stack
  height: 32px

.cp-tabs
  margin: 15px 0 21px 40px
  min-height: 25px !important
  .cp-tab
    padding: 0
    margin-right: 40px
    align-items: start
    min-height: 25px
    min-width: auto
    font-size: 13px
    font-weight: 500
    padding-bottom: 7px

p.error
  text-align: center
  margin-top: 26px
  color: $parso-danger-light

.amenitie
  border: solid 1px #A6A6A6
  border-radius: 10px
  display: inline-block
  padding: 7px 10px
  margin: 0 7px 7px 0
  font-size: 12px
  line-height: 11px
  color: black
  font-weight: bold
  svg
    margin-right: 5px
    width: 12px
    height: 12px
    vertical-align: middle

.amenities-label
  display: block
  margin: 12px 0 12px
  font-size: 14px

.leaflet-container
  background: white
  outline: 0

.cp-card.MuiCard-root
  min-width: 340px
  min-height: 167px
  width: calc(100% - 6px)
  box-shadow: 0px 0px 3px rgba(146, 146, 146, 0.5)
  border-radius: 5px
  margin: 0 6px 11px 0
  cursor: pointer

.cp-button.cp-button
  box-shadow: none
  font-weight: 500
  font-size: 16px
  line-height: 16px
  padding-top: 10px
  padding-bottom: 10px
  height: 36px
  &.Mui-disabled
    background-color: #EFEFEF !important

.cp-icon-menu.MuiButtonBase-root
  padding: 0
  margin-right: 17px
  vertical-align: sub

.arrow-back
  transform: rotate(180deg)
.MuiButtonBase-root.close-button
  position: absolute
  top: 16px
  right: 16px
  color: #4A4A68

.popup-marker
  .MuiButtonBase-root.close-button
    padding: 0
  .header
    color: $parso-high-gray-blue
    margin-bottom: 3px
    svg
      vertical-align: sub
      margin-right: 11px
  .popup-image
    width: 100%
    border-radius: 7px
    margin-bottom: 13px

.leaflet-popup-content-wrapper, .leaflet-popup-tip
  border-radius: 4px

.detail-list
  color: $parso-high-gray
  font-size: 14px
  list-style: none
  margin: 0
  padding: 0
  margin-bottom: 100px
  overflow-y: scroll
  li
    margin-bottom: 20px
    &.time-wrapper
      margin-bottom: 0px

      & > span
        position: relative
        top: -7px

    & > svg
      vertical-align: middle
      margin-right: 5px

    & > span
      margin-left: 10px
      color: $parso-denied

.detail-list-workplace

  .detail-list
    display: inline-block
    vertical-align: top
    width: 350px

  .detail-list-map
    height: calc($vh-100 - 335px)
    display: inline-block
    background: $parso-gray
    width: calc(100% - 350px)
    min-height: 300px
    max-height: 400px
    .leaflet-container
      height: 100%

.time-picker-button
  margin: 0
  background: #ECF1F4
  width: 116px
  border: 0
  border-radius: 3px
  vertical-align: middle

  input, button
    font-size: 12px

  button
    padding-right: 5px
    svg
      width: 14px

  input
    padding: 6px 5px
    color: $parso-high-gray

.select-button
  background: #ECF1F4
  & > div
    padding: 6px 5px
    font-size: 12px
    color: $parso-high-gray

.form-input-group > .MuiDivider-root
  display: none

h1.center-message
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
  margin: auto
  display: block
  width: 204px
  height: 23px
  text-align: center

.dialog-detail.detail-map
  .MuiDialogContent-root.dialog-detail-content
    .legend
      margin-bottom: 20px
      font-size: 16px
      line-height: 22px
      color: $parso-high-gray-blue
      letter-spacing: 0.015em
      svg
        vertical-align: text-top
        margin-right: 2px

  .detail-map-header
    h1
      color: $parso-semi-black
      font-weight: 500
      font-size: 16px
      line-height: 27px
    h2
      color: rgba(32, 31, 30, 0.58)
      font-size: 15px
      line-height: 27px
      margin-bottom: 15px
  .detail-map-footer
    text-align: right

.image-information-dialog
  .MuiPaper-root
    .MuiDialogContent-root.dialog-info-content
      .detail-header
        font-size: 24px
        font-weight: 500
        line-height: 33px
      img
        width: 321px
        margin: 10px auto 0
        display: block
      p.enabled
        font-size: 16px
        line-height: 22px

svg
  &.PENDING
    color: $parso-pending
  &.APPROVED
    color: $parso-approved
  &.DENIED
    color: $parso-denied
  &.DANGER
    color: $parso-danger-light
  &.SUCCESS
    color: $parso-success-strong

.detail-map
  .dialog-detail-content
    padding: 20px 0 0 0
    overflow-y: hidden
  .MuiTextField-root
    background: $parso-light-blue-light-gray
    border-radius: 6px
    width: 244px

    .MuiOutlinedInput-notchedOutline
      border: none

    &.big-text-field
      width: 100px
      .MuiOutlinedInput-root .Mui-disabled
        font-size: 24px
        font-weight: 900
        padding: 10px
        -webkit-text-fill-color: $parso-high-gray
        color: $parso-high-gray

    .MuiOutlinedInput-root .Mui-disabled
      font-size: 14px
      font-weight: 500
      padding: 10px
      -webkit-text-fill-color: $parso-high-gray
      color: $parso-high-gray
  
  .steps
    position: absolute
    top: 100px
    right: 1px
    padding: 24px
    height: auto
    max-height: calc(90vh - 100px)
    overflow: auto
    z-index: 1000
    .instructions-close-button, p 
      color: $parso-blue
    p
      font-display: 'Nunito Sans'
      font-size: 18px
      margin-right: 24px


  .explore-floor-map-detail
    width: 100%
    height: calc($vh-100 - 141px)

.dialog-title
  font-family: 'Nunito Sans'
  font-size: 20px
  font-style: normal
  font-weight: 700
  line-height: normal
  margin-bottom: 16px
  padding-left: 24px
  button
    color:$parso-blue !important
    svg
      font-size: 36px

.cp-select
  width: 140px
  font-size: 13px
  background: #FFFFFF
  border: none
  box-sizing: border-box
  border-radius: 6px

  &.MuiOutlinedInput-root.MuiOutlinedInput-root
    height: 33px
    box-shadow: 0px 0.769939px 3.07975px $parso-background
    border-radius: 6px

  &.cp-select-big.MuiOutlinedInput-root.MuiOutlinedInput-root
    height: auto
    width: 348px

  &.MuiFormControl-root.MuiTextField-root
    margin-top: -10px
    .MuiOutlinedInput-root
      height: inherit

.static-detail-map-filters
  margin-bottom: 16px
  padding-left: 24px
  .cp-select.MuiOutlinedInput-root.MuiOutlinedInput-root
    background: $parso-light-blue-light-gray
    box-shadow: none
    .Mui-disabled
      -webkit-text-fill-color: $parso-high-gray
      color: $parso-high-gray
    .MuiOutlinedInput-notchedOutline
      border: none !important
  .route-icons
    color: #9B9B9B
  .route
    font-family: Nunito Sans
    font-size: 18px
    font-style: normal
    font-weight: 600
    line-height: normal

.center
  text-align: center

.leaflet-container a.leaflet-popup-close-button
  display: none

.room-marker
  h1
    color: black
    font-weight: 500
    font-size: 14px
    background: rgba(255, 255, 255, 0.5)
    padding: 2px 10px
    display: inline-block
    width: 70px
    overflow: hidden
    left: -28px
    position: relative
    text-align: center
    text-overflow: ellipsis

@media screen and (min-width: $md)
  h1
    font-size: 24px

  .cp-tabs
    margin: 15px 0 32px 0
    .cp-tab
      font-size: 18px

  .cp-card.MuiCard-root
    max-width: 350px
    height: 255px
    margin-right: 28px
    margin-bottom: 28px
    box-shadow: 0px 0px 6px rgba(146, 146, 146, 0.5)

  .form-input-group
    background: $parso-light-blue-light-gray
    border-radius: 6px

    & > .MuiDivider-root
      display: block

  .image-information-dialog
    .MuiPaper-root
      .MuiDialogContent-root.dialog-info-content
        p.enabled
          width: 376px
          margin: 10px auto 29px
        .detail-footer
          width: 217px
          margin-bottom: 20px
