@import '../../../variables'

.FloorMap
  .leaflet-container
    height: 101%
    background: -moz-linear-gradient(170deg, rgba(255,255,255,1) 70%, rgba(108,133,211,0.22452731092436973) 83%)
    background: -webkit-linear-gradient(170deg, rgba(255,255,255,1) 70%, rgba(108,133,211,0.22452731092436973) 83%)
    background: linear-gradient(170deg, rgba(255,255,255,1) 70%, rgba(108,133,211,0.22452731092436973) 83%)
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#6c85d3",GradientType=1)

  .leaflet-control-container
    display: none

  .svg-icon
    background: trasparent
    border: none
    circle
      fill: #6C85D3
    &.active
      border-radius: 50%
      animation-name: active-icon
      animation-duration: 3s
      animation-iteration-count: infinite
      circle
        fill: #F39C12
    &.selected
      circle
        fill: #F39C12
    

@keyframes active-icon
  0%
    box-shadow: 0 0 0 0 #f39d1260
  50%
    box-shadow: 0px 0px 0 15px #f39d1260
  100%
    box-shadow: 0 0 0 0 #f39d1260

.leaflet-popup
  width: 341px
  img
    width: 341px
    margin-left: -21px
    margin-bottom: 20px
    margin-top: 5px
