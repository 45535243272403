@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Black.otf') format('opentype')
  font-weight: 900
  font-style: normal

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Heavy.otf') format('opentype')
  font-weight: 800
  font-style: normal

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-MediumOblique.otf') format('opentype')
  font-weight: 500
  font-style: oblique

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Medium.otf') format('opentype')
  font-weight: 500
  font-style: normal

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Oblique.otf') format('opentype')
  font-weight: 400
  font-style: oblique

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Roman.otf') format('opentype')
  font-weight: 400
  font-style: italic

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-LightOblique.otf') format('opentype')
  font-weight: 300
  font-style: oblique

@font-face
  font-display: swap
  font-family: 'Avenir'
  src: url('./AvenirLTStd-Light.otf') format('opentype')
  font-weight: 300
  font-style: normal

@font-face
  font-display: swap
  font-family: 'Nunito Sans'
  src: url('./NunitoSans-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype')
  font-weight: 400
  font-style: normal

@font-face
  font-display: swap
  font-family: 'Nunito Sans'
  src: url('./NunitoSans-Italic-VariableFont_YTLC,opsz,wdth,wght.ttf') format('truetype')
  font-weight: 400
  font-style: italic