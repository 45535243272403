@import '../../../variables'

.detail-fullscreen
  .detail-header
    font-size: 18px
    svg
      font-size: 40px
  .fullscreen-password
    margin: 9px 0
  .detail-fullscreen-info
    font-size: 12px
    line-height: 17px
    color: $parso-high-gray

.FloorMapControls
  *
    font-family: 'Nunito Sans'
  .fullScreen
    margin-top: 40px
  .MuiTypography-root, .MuiInputLabel-root, .MuiOutlinedInput-root
    font-family: 'Nunito Sans'
    font-size: 18px
    font-weight: 600
  .filters-inner-wrapper, .full-screen, .menu-options
    position: absolute
    z-index: 1000
    background: rgba(255, 255, 255)
    path
      fill: $parso-high-gray-blue
  .filters-inner-wrapper
    padding: 16px
    top: 20px
    left: 48px
    width: 265px
    overflow: hidden
    transition: ease 0.5s width
    h3
      color: $parso-blue-strong
      font-size: 20px
      font-weight: 700
      font-style: normal
      line-height: normal
    .expand-menu-button
      padding: 0 0 0 4px
      path
        fill: $parso-blue

    .MuiSelect-root, .MuiOutlinedInput-root, .MuiAutocomplete-root
      height: auto
      width: 265px
      background: white
      margin-top: 8px
      border-radius: 6px

    .MuiSelect-select, .search-space-input > div
      padding: 10px 14px
      margin-top: 0
    
    .MuiOutlinedInput-root
      input
        padding: 0 !important
    

  @keyframes expand-animation
    from
      background-color: red
    to
      background-color: yellow

  .full-screen
    width: 72px
    height: 72px
    padding: 7px
    bottom: 20px
    right: 20px
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.25)
    border-radius: 4px
    svg
      width: 58px
      height: 58px
  .menu-options
    padding: 10px
    top: 20px
    right: 20px
